import {
  SET_INTERVIEW_SERVICE_URL,
  SET_URL_FETCH_STATUS,
  SET_SEGMENTS,
  SET_SELECTED_SEGMENT,
  SET_QUESTIONNAIRE,
  ADD_ANSWER,
  UPDATE_ANSWER,
  RESET_INTERVIEW,
  SET_ACTIVE_STEP,
  SET_INTERVIEW_TYPE,
  SET_INTERVIEW_GOAL,
  SET_INTERVIEW_TASK_ID,
  EDIT_QUESTION,
  ADD_NEW_QUESTION,
  DELETE_SECTION,
  DIVE_DEEPER,
  SET_SECTION_LOADING,
  UPDATE_INTERVIEWS_HISTORY,
  SET_INTERVIEWS_HISTORY_RESULT,
  SET_INTERVIEWS_PAGINATION,
  SET_SELECTED_INTERVIEW,
  SET_INTERVIEW_RUNNING,
  SET_INTERVIEW_THEMES,
  SET_TASK_STATUS,
  SET_TASK_PROGRESS,
  SET_TASK_ERROR,
  SET_SEGMENTATION_TASK_ID,
  SET_QUESTIONNAIRE_TASK_ID,
  SET_QUESTIONNAIRE_STATUS,
  SET_QUESTIONNAIRE_PROGRESS,
  SET_QUESTIONNAIRE_ERROR,
  SET_INTERVIEW_INSIGHTS,
  SET_AFFINITY_MAPPINGS,
  SET_INTERVIEW_STATUS,
  SET_ANALYSIS_STATUS,
  SET_LOADER_STATUS
} from './actions';

// Initial State
const initialState = {
  activeStep: 0,
  interviewServiceUrl: '',
  urlFetchStatus: 'idle',
  taskStatus: null,
  taskProgress: 0,
  taskError: null,
  segments: {},
  selectedSegment: null,
  questionnaire: [],
//   answers: [],
  taskId: null,
  segmentationTaskId: null,
  questionnaireTaskId: null,
  questionnaireStatus: null,
  questionnaireProgress: 0,
  questionnaireError: null,
  sectionLoadingStates: {},
  interviewsHistory: [],
  historyResult: null,
  pagination: 0,
  selectedInterview: null,
  isInterviewRunning: false,
  themes: null,
  insights: null,
  affinityMappings: null,
  interviewStatus: {
    completed: 0,
    total: 0,
    inProgress: false,
    isComplete: false
  },
  analysisStatus: {
    status: null,
    progress: 0,
    inProgress: false,
    started: false
  },
  loaderStatus: null,
};

// Reducer
const interviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERVIEW_SERVICE_URL:
      return { ...state, interviewServiceUrl: action.payload };
    case SET_URL_FETCH_STATUS:
      return { ...state, urlFetchStatus: action.payload };
    case SET_SEGMENTS:
      return { ...state, segments: action.payload };
    case SET_SELECTED_SEGMENT:
      return { ...state, selectedSegment: action.payload };
    case SET_QUESTIONNAIRE:
      return { ...state, questionnaire: action.payload };
    case ADD_ANSWER:
      return { ...state, answers: [...state.answers, action.payload] };
    case UPDATE_ANSWER:
      return {
        ...state,
        answers: state.answers.map(answer =>
          answer.questionId === action.payload.questionId ? action.payload : answer
        ),
      };
    case RESET_INTERVIEW:
      if (action.payload?.preserveHistory) {
        // Preserve history-related state while resetting everything else
        return {
          ...initialState,
          interviewsHistory: state.interviewsHistory,
          pagination: state.pagination
        };
      }
      return initialState;
    case SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case SET_INTERVIEW_TYPE:
      return { ...state, interviewType: action.payload };
    case SET_INTERVIEW_GOAL:
      return { ...state, interviewGoal: action.payload };
    case SET_INTERVIEW_TASK_ID:
      return {
        ...state,
        taskId: action.payload
      };
    case EDIT_QUESTION:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questions: state.questionnaire.questions.map((section, idx) =>
            idx === action.payload.sectionIndex
              ? {
                  ...section,
                  questions: section.questions.map((q, qIdx) =>
                    qIdx === action.payload.questionIndex ? action.payload.newQuestion : q
                  )
                }
              : section
          )
        }
      };
    case ADD_NEW_QUESTION:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questions: state.questionnaire.questions.map((section, idx) =>
            idx === action.payload.sectionIndex
              ? {
                  ...section,
                  questions: [...section.questions, action.payload.question]
                }
              : section
          )
        }
      };
    case DELETE_SECTION:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questions: state.questionnaire.questions.filter((_, index) => index !== action.payload)
        }
      };
    case DIVE_DEEPER:
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questions: state.questionnaire.questions.map((section) =>
            section.title === action.payload.section_title
              ? {
                  ...section,
                  questions: [...section.questions, ...action.payload.newQuestions]
                }
              : section
          )
        }
      };
    case SET_SECTION_LOADING:
      return {
        ...state,
        sectionLoadingStates: {
          ...state.sectionLoadingStates,
          [action.payload.sectionTitle]: action.payload.isLoading
        }
      };
    case UPDATE_INTERVIEWS_HISTORY:
      return {
        ...state,
        interviewsHistory: action.payload
      };
    case SET_INTERVIEWS_HISTORY_RESULT:
      return {
        ...state,
        historyResult: action.payload
      };
    case SET_INTERVIEWS_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case SET_SELECTED_INTERVIEW:
      return {
        ...state,
        selectedInterview: action.payload
      };
    case SET_INTERVIEW_RUNNING:
      return {
        ...state,
        isInterviewRunning: action.payload
      };
    case SET_INTERVIEW_THEMES:
      return {
        ...state,
        themes: action.payload
      };
    case SET_TASK_STATUS:
      return {
        ...state,
        taskStatus: action.payload
      };
    case SET_TASK_PROGRESS:
      return {
        ...state,
        taskProgress: action.payload
      };
    case SET_TASK_ERROR:
      return {
        ...state,
        taskError: action.payload
      };
    case SET_SEGMENTATION_TASK_ID:
      return { ...state, segmentationTaskId: action.payload };
    case SET_QUESTIONNAIRE_TASK_ID:
      return { ...state, questionnaireTaskId: action.payload };
    case SET_QUESTIONNAIRE_STATUS:
      return { ...state, questionnaireStatus: action.payload };
    case SET_QUESTIONNAIRE_PROGRESS:
      return { ...state, questionnaireProgress: action.payload };
    case SET_QUESTIONNAIRE_ERROR:
      return { ...state, questionnaireError: action.payload };
    case SET_INTERVIEW_INSIGHTS:
      return {
        ...state,
        insights: action.payload
      };
    case SET_AFFINITY_MAPPINGS:
      return {
        ...state,
        affinityMappings: action.payload
      };
    case SET_INTERVIEW_STATUS:
      return {
        ...state,
        interviewStatus: action.payload
      };
    case SET_ANALYSIS_STATUS:
      return {
        ...state,
        analysisStatus: action.payload
      };
    case SET_LOADER_STATUS:
      return {
        ...state,
        loaderStatus: action.payload
      };
    default:
      return state;
  }
};

export default interviewReducer;
