import { lazy } from 'react';

import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import CommonLayout from 'layout/CommonLayout';

import Loadable from 'components/Loadable';
const PagesLanding = Loadable(lazy(() => import('pages/landing')));
const InterviewsLanding = Loadable(lazy(() => import('pages/inter')));


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <PagesLanding />
        }
      ]
    },
    {
      path: '/interviews-landing',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '',
          element: <InterviewsLanding />
        }
      ]
    },
    LoginRoutes, MainRoutes]);
}
