// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  // ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  SettingOutlined,
  AimOutlined,
  RadarChartOutlined,
  StarOutlined,
  TeamOutlined
} from '@ant-design/icons';

// icons
const icons = {
  RadarChartOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  SettingOutlined,
  AimOutlined,
  StarOutlined,
  TeamOutlined
};
// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
  // title: <FormattedMessage id="others" />,
  type: 'group',
  children: [

    {
      id: 'lp-analysis',
      title: <FormattedMessage id="lp-analysis" />,
      type: 'item',
      url: '/lp-analysis',
      icon: icons.RadarChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'competitors-history',
      title: <FormattedMessage id="competitors-history" />,
      type: 'item',
      url: '/competitors-history',
      icon: icons.AimOutlined,
      breadcrumbs: false
    },
    {
      id: 'reviews',
      title: <FormattedMessage id="reviews" />,
      type: 'item',
      url: '/reviews',
      icon: icons.StarOutlined,
      breadcrumbs: false
    },

    {
      id: 'interviews',
      title: <FormattedMessage id="interviews" />,
      type: 'item',
      url: '/interviews',
      icon: icons.TeamOutlined,
      breadcrumbs: false
    }
  ]
};

export default other;
