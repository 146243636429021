import { CLOSE_BANNER, RESET_BANNER } from './actions';

const initialState = {
  isVisible: true
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_BANNER:
      return {
        ...state,
        isVisible: false
      };
    case RESET_BANNER:
      return {
        ...state,
        isVisible: true
      };
    default:
      return state;
  }
};

export default bannerReducer; 